<template>
    <section class="min-w-screen animation-fade animation-delay relative bg-white">
        <div class="sm:px-12 xl:px-5 container px-0 px-8 mx-auto">
            <p
                class="sm:mx-6 sm:text-center sm:text-normal sm:font-bold text-xs font-bold text-left text-pink-500 uppercase"
            >
                Wondered what burn and mint means?
            </p>
            <h3
                class="sm:mx-6 sm:text-3xl md:text-4xl lg:text-5xl sm:text-center sm:mx-0 mt-1 text-2xl font-bold text-left text-gray-800"
            >
                Check out our features
            </h3>
            <div
                class="sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3 w-full px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg"
            >
                <h3 class="sm:text-xl md:text-2xl text-lg font-bold text-purple-500">Custom token- name and symbol</h3>
                <p class="sm:text-lg md:text-normal mt-2 text-base text-gray-600">
                    <b>Custom token name</b> means you'll be able to decide the name for your token
                    <b>Example: FreeToken.</b><br />
                    <b>Custom token symbol</b> means you'll be able to decide the symbol of your token, this is most
                    likely an abbreviation of your token name. <b>Example: FREE</b>
                </p>
            </div>
            <div
                class="sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3 w-full px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg"
            >
                <h3 class="sm:text-xl md:text-2xl text-lg font-bold text-purple-500">Fixed or capped supply</h3>
                <p class="sm:text-lg md:text-normal mt-2 text-base text-gray-600">
                    <b>Fixed supply</b> means the initial supply will be sent to the owner wallet on deployment. You
                    wont be able to increase or decrease the circulating supply at any point.<br />
                    <b>Capped supply</b> means you'll select an initial supply which will be sent to ownerwallet. You
                    can later mint more tokens into circulation. You cannot mint more than your defined supply cap.
                </p>
            </div>
            <div
                class="sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3 w-full px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg"
            >
                <h3 class="sm:text-xl md:text-2xl text-lg font-bold text-purple-500">Verified contracts on BSCScan</h3>
                <p class="sm:text-lg md:text-normal mt-2 text-base text-gray-600">
                    Verified contracts is a way to prove to your community, that the sourcecode behind your token is
                    valid and not full of bugs. All contracts deployed from
                    <a href="https://tokengen.net/" class="underline">Tokengen</a> is automatically verified on BSCScan
                    on the premisses of being "similar match". You can read more about how Similar Match works
                    <a
                        href="https://ethereum.stackexchange.com/a/64727"
                        class="text-blue-500 underline"
                        target="_blank"
                        rel="noopener"
                        >here</a
                    >.
                </p>
            </div>

            <div
                class="sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3 w-full px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg"
            >
                <h3 class="sm:text-xl md:text-2xl text-lg font-bold text-purple-500">Mintable tokens</h3>
                <p class="sm:text-lg md:text-normal mt-2 text-base text-gray-600">
                    Minting tokens means you'll be able to mint tokens into circulation. You will not be able to mint
                    tokens than your max supply defined when making your contract. <br />
                    Example: Initial supply of 1,000,000 with a max supply of 5,000,000. You'll be able to mint
                    4,000,000 tokens into circulation.
                </p>
            </div>
            <div
                class="sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3 w-full px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg"
            >
                <h3 class="sm:text-xl md:text-2xl text-lg font-bold text-purple-500">Burnable tokens</h3>
                <p class="sm:text-lg md:text-normal mt-2 text-base text-gray-600">
                    Burnable tokens means you'll be able to burn(destroy) token from your circulation. These tokens are
                    sent to a dead wallet that noone has access to. This is mostly done to decrease the circulating
                    supply to keep up the liquidity of your token.
                </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Features',
};
</script>

<style scoped></style>
